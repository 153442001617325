import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTableData } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA_20 } from 'constants/common';
import { useInterval, useLogHandleTime, usePrevious } from 'hooks';
import { executionService } from 'resources';
import { selectWorkflow } from 'store/workflow';
import { IExecutionData } from 'types';
import {
  calculateCountOfPages,
  getThreeDaysDefaultFilter,
  prepareTableId,
} from 'utils';

import { getColumns } from '../columns';
import { getDefaultPagination, getDefaultSorting } from '../constants';
import { getAdditionalFilters } from '../filters';
import { getUsername } from '../helpers';
import { TGetApprovalsProps, THandleCellClickProps } from '../types';

import { useBulkActions } from './useBulkActions';

export const useApprovalsPage = () => {
  const navigate = useNavigate();
  const [onActionLoading, setOnActionLoading] = useState(false);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
  const initialLoad = useRef(true);
  const { data: workflowOptions } = useSelector(selectWorkflow);
  const tableId = prepareTableId('approvals');
  const [isError, setIsError] = useState(false);

  const { logHandleTime, setStartHandleTime } =
    useLogHandleTime('approvals-page');

  const getApprovals = useCallback(
    ({ params: { approvers, skip, ...rest } }: TGetApprovalsProps) => {
      setStartHandleTime();
      let username = '';

      if (window.runUIhistoryContainer) {
        username = getUsername();
      }

      if (initialLoad.current && username.includes('@')) {
        initialLoad.current = false;

        return executionService.fetchExecutions(
          {
            ...rest,
            approver: username,
            offset: skip,
            status: 'suspended',
          },
          setIsError,
        );
      }

      return executionService.fetchExecutions(
        {
          ...rest,
          approver: approvers,
          offset: skip,
          status: 'suspended',
          approvals_only: true,
        },
        setIsError,
      );
    },
    [setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getApprovals },
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
      saveViewParamsAfterLeave: true,
      tableId,
    }),
    [getApprovals, tableId],
  );

  const {
    data,
    fetchData: refetch,
    filters,
    isLoading,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    setSorting,
  } = useTableData<IExecutionData>(tableDataArgs);

  const { bulkActions, marketValue, selectedRows, setSelectedRows } =
    useBulkActions({ refetch, setOnActionLoading });

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        workflowOptions,
      }),
    [removeFilter, setFilter, selectedRows, workflowOptions],
  );
  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getThreeDaysDefaultFilter(),
      }),
    [filters, removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [additionalFilters, resetFilters, filters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      total,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPage,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const getRowProps = () => ({
    style: {
      cursor: 'pointer',
    },
  });

  const handleCellClick = useCallback(
    ({ row: { original } }: THandleCellClickProps) => {
      const {
        execution_id: executionId,
        info,
        wf_type: wfType,
        workflow,
      } = original;

      if (executionId) {
        navigate(`/wfe/approvals/${executionId}`, {
          state: {
            locationInfo: !!info?.length,
            locationWfType: wfType,
            locationWorkflow: workflow,
          },
        });
      }
    },
    [navigate],
  );

  const previousApprovals = usePrevious(data?.results);

  const handleClickOnRefresh = useCallback(() => {
    setSelectedRows([]);
    setIsRefreshBtnClicked(true);
    refetch();
  }, [refetch]);

  const clearInterval = useInterval(() => {
    setSelectedRows([]);
    refetch();
  }, DEFAULT_POOLING_INTERVAL_DATA_20);

  useEffect(() => {
    if (isError) {
      clearInterval();
    }
  }, [isError]);

  useEffect(() => {
    if (data?.results && !isEqual(previousApprovals, data.results)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousApprovals]);

  useEffect(() => {
    if (isRefreshBtnClicked && !isLoading) {
      setIsRefreshBtnClicked(false);
    }
  }, [isLoading, isRefreshBtnClicked]);

  return {
    bulkActions,
    columns,
    data,
    filteringProps,
    getRowProps,
    handleClickOnRefresh,
    handleCellClick,
    isLoading,
    isRefreshBtnClicked,
    marketValue,
    onActionLoading,
    serverPaginationProps,
    setSorting,
    tableId,
  };
};
